.dropdown-content {
  display:none;
  @extend .z-depth-1;
  position: absolute;
  background-color: #FFFFFF;
  margin: 0px;
  min-width: 100px;
  z-index: 1000;
  max-height: 70%;
  opacity: 0;
  overflow-y: hidden;

  will-change: width, height;

  li {
    cursor: pointer;
    font-size: 1.2rem;
    color: $off-black;
    line-height: 1.5rem;
		

    &:hover, &.active {
      background-color: rgba(0,0,0, .06);
    }

    & > a, & > span {
      display: block;
      padding: 10px 20px;
			text-transform: capitalize;
			
      color: $dropdown-color;
    }
  }
}
